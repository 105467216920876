.moon-container {
    grid-area: moon;
    justify-self: right;
    position: relative;
    @media screen and (min-width: 1024px) {
        align-self: start;
        justify-self: unset;
    }

}

.sphere {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    display: inline-block;
    overflow: hidden;
    position: relative;
}


.light {
    border-radius: 50%;
    filter: blur(0.025px);
    height: 100px;
    width: 100px;
  }
  
  .texture {
    border-radius: 50%;
    background-image: url('../../assets/real-moon.png');
    background-position: center center;
    background-size: 100px;
    background-repeat: no-repeat;
    height: 100px;
    left: 0;
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    width: 100px;
  }
  
  .circle {
    border-radius: 50%;
    box-shadow:
        inset 0 0 12px #0c1c2e,
        inset 0 0 8px #0c1c2e,
        inset 0 0 8px #0c1c2e;
    filter: blur(0.005px);
    height: 102px;
    position: absolute;
    top: -1px;
    width: 102px;
  }