.nav-link {
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
}

.nav-link {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    opacity: 0.6;
    &:before {
        content: '';
        background-color: var(--color-white);
        height: 1px;
        transition: width 0.3s ease-in-out;
        width: 25px;
    }
    &:hover {
        opacity: 1;
        &:before {
            width: 50px;
        }
    }
    &.active {
        opacity: 1;
        &:before {
            width: 50px;
        }
    }
}

button {
    background-color: #4a4a4a;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    text-decoration: none;
}

.skill-pill {
    background-color: var(--color-aqua-darkest);
    border-radius: 25px;
    color: var(--color-aqua);
    cursor: default;
    font-size: var(--step--2);
    font-weight: 300;
    cursor: pointer;
}