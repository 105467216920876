header.header {
    display: grid;
    grid-area: header;
    grid-template-rows: auto 1fr;
    grid-gap: var(--space-m);
    
    @media screen and (min-width: 1024px) {
        grid-gap: var(--space-2xl);
        grid-template-rows: auto auto 1fr;
        height: calc(100vh - var(--header-padding));
        padding-bottom: 120px;
        position: sticky;
        top: var(--header-padding);
    }
    .header-content {
        display: grid;
        grid-gap: var(--space-3xs-s);
    }
    p {
        max-width: 330px;
    }
    .nav {
        display: none;

        @media screen and (min-width: 768px) {
            display: block;
        }

        ul {
            display: grid;
            grid-gap: var(--space-2xs);
        }
    }
    .contact-section {
        align-self: end;
        padding: 2px;
    }
}