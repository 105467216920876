.ibm-thin {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .ibm-extralight {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .ibm-light {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .ibm-regular {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .ibm-medium {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .ibm-semibold {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .ibm-bold {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .ibm-thin-italic {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .ibm-extralight-italic {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .ibm-light-italic {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .ibm-regular-italic {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .ibm-medium-italic {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .ibm-semibold-italic {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .ibm-bold-italic {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  /* @link https://utopia.fyi/type/calculator?c=320,18,1.2,1240,20,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l|m-xl|3xs-s&g=s,l,xl,12 */

:root {
    --step--2: clamp(0.7813rem, 0.7747rem + 0.0326vw, 0.8rem);
    --step--1: clamp(0.9375rem, 0.9158rem + 0.1087vw, 1rem);
    --step-0: clamp(1.125rem, 1.0815rem + 0.2174vw, 1.25rem);
    --step-1: clamp(1.35rem, 1.2761rem + 0.3696vw, 1.5625rem);
    --step-2: clamp(1.62rem, 1.5041rem + 0.5793vw, 1.9531rem);
    --step-3: clamp(1.944rem, 1.771rem + 0.8651vw, 2.4414rem);
    --step-4: clamp(2.3328rem, 2.0827rem + 1.2504vw, 3.0518rem);
    --step-5: clamp(2.7994rem, 2.4462rem + 1.7658vw, 3.8147rem);
  }

.h1 {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: var(--step-5);
}
.h2 {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: var(--step-4);
}

.h3, .h4, .h5, .h6 {
    font-size: var(--step-3);
}

.p {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: var(--step-0);
    opacity: 0.6;
}

.eyebrow {
    text-transform: uppercase;
    font-size: var(--step--2);
}