
.about {
    display: grid;
    grid-column: 1;
    grid-row: 1;
    row-gap: var(--space-s-m);
    @media screen and (min-width: 1024px) {
        row-gap: 0px;
        .h2 {
            height: 0px;
            width: 0px;
            visibility: hidden;
        }
    }
}