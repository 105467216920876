.layout {
    display: grid;
    grid-template-areas: 'header' 'moon' 'main';
    grid-template-columns: 1fr;
    position: relative;
    z-index: 1;
}

@media screen and (min-width: 1024px) {
    .layout {
        grid-gap: var(--space-xl);
        grid-template-columns: 100px 2fr 3fr;
        grid-template-areas: 'moon header main';
        padding-top: var(--header-padding);
    }

    .layout > *:last-child {
        overflow-y: auto;
    }
}