.experience-item {
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: var(--space-xs-s);
    padding: var(--space-s);
    transition: background-color 0.3s ease, opacity 0.3s ease;
    
    &.dimmed {
      opacity: 0.6;
    }
    &:hover {
        background-color: var(--color-navy-light);

        .title, .external-arrow {
            color: var(--color-aqua);
        }

        .external-arrow svg{
            fill: var(--color-aqua);
            transform: translate(2px, -2px);
        }
    }

    .date {
        font-size: var(--step--2);
        text-align: right;
        @media screen and (min-width: 768px) {
            grid-column: 1 / 2;
            text-align: left;
        }
    }

    .content {
        display: grid;
        @media screen and (min-width: 768px) {
            grid-column: 2 / 3;
        }

    }

    .title {
        align-items: center;
        display: flex;
        gap: var(--space-3xs);
        font-size: var(--step-0);
        margin-bottom: var(--space-2xs);
        transition: color 0.3s ease;

        .external-arrow svg{
            height: 16px;
            width: 16px;
            transition: fill 0.3s ease, transform 0.3s ease;
        }
    }

    .company {
        opacity: 0.8;
    }

    .description {
        font-size: var(--step-0);
        line-height: 1.05;
        margin-bottom: var(--space-m);
    }

    .links {
        cursor: default;
        display: grid;
        margin-bottom: var(--space-xs);
        row-gap: var(--space-3xs);
    }
    
    .link {
        align-items: center;
        display: flex;
        gap: var(--space-3xs);
        font-size: var(--step--1);
        transition: color 0.3s ease;
        svg {
            height: 16px;
            width: 16px;
        }
        &:hover {
            color: var(--color-aqua);
        }
    }
    
    .skills {
        display: flex;
        flex-wrap: wrap;
        gap: var(--space-3xs);
    }
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .experience-item {
    grid-template-columns: 1fr;
    gap: var(--space-2xs);
  }

  .date,
  .content {
    grid-column: 1 / -1;
  }
}
