:root {
    --header-padding: 20px;
}

@media screen and (min-width: 768px) {
    :root {
        --header-padding: 50px;
    }
}

@media screen and (min-width: 1024px) {
    :root {
        --header-padding: 120px;
    }
}

/* Colors */
:root {
    --color-white: #fff;
    --color-navy: #0c1c2e;
    --color-navy-light: #1a2a3a;
    --color-aqua: #71d3e6;
    --color-aqua-dark: #0093a8;
    --color-aqua-darker: #006d7e;
    --color-aqua-darkest: #004d57;
    --color-green: #2ecc71;
    --color-lime: #32cd32;
    --color-forest: #228b22;
    --moon-light: #C3C2BE;
    --moon-dark: #31302E;

}


/* @link https://utopia.fyi/space/calculator?c=320,18,1.2,1240,20,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
    --space-3xs: clamp(0.3125rem, 0.3125rem + 0vw, 0.3125rem);
    --space-2xs: clamp(0.5625rem, 0.5408rem + 0.1087vw, 0.625rem);
    --space-xs: clamp(0.875rem, 0.8533rem + 0.1087vw, 0.9375rem);
    --space-s: clamp(1.125rem, 1.0815rem + 0.2174vw, 1.25rem);
    --space-m: clamp(1.6875rem, 1.6223rem + 0.3261vw, 1.875rem);
    --space-l: clamp(2.25rem, 2.163rem + 0.4348vw, 2.5rem);
    --space-xl: clamp(3.375rem, 3.2446rem + 0.6522vw, 3.75rem);
    --space-2xl: clamp(4.5rem, 4.3261rem + 0.8696vw, 5rem);
    --space-3xl: clamp(6.75rem, 6.4891rem + 1.3043vw, 7.5rem);
  
    /* One-up pairs */
    --space-3xs-2xs: clamp(0.3125rem, 0.2038rem + 0.5435vw, 0.625rem);
    --space-2xs-xs: clamp(0.5625rem, 0.4321rem + 0.6522vw, 0.9375rem);
    --space-xs-s: clamp(0.875rem, 0.7446rem + 0.6522vw, 1.25rem);
    --space-s-m: clamp(1.125rem, 0.8641rem + 1.3043vw, 1.875rem);
    --space-m-l: clamp(1.6875rem, 1.4049rem + 1.413vw, 2.5rem);
    --space-l-xl: clamp(2.25rem, 1.7283rem + 2.6087vw, 3.75rem);
    --space-xl-2xl: clamp(3.375rem, 2.8098rem + 2.8261vw, 5rem);
    --space-2xl-3xl: clamp(4.5rem, 3.4565rem + 5.2174vw, 7.5rem);
  
    /* Custom pairs */
  --space-s-l: clamp(1.125rem, 0.6467rem + 2.3913vw, 2.5rem);
  --space-m-xl: clamp(1.6875rem, 0.9701rem + 3.587vw, 3.75rem);
  --space-3xs-s: clamp(0.3125rem, -0.0136rem + 1.6304vw, 1.25rem);
  }