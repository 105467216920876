.contact-buttons {
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 100%;
}
.contact-button svg {
    height: 24px;
    width: 24px;
    transition: fill 0.2s ease;
    &:hover {
        fill: var(--color-aqua);
    }
}
