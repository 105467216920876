body {
  background-color: var(--color-navy);
}

.App {
  background-color: var(--color-navy);
  color: var(--color-white);
  font-family: Arial, sans-serif;
  margin: 0 auto;
  max-width: 1500px;
  padding: 0 20px;
  text-align: left;

  main { 
    display: grid;
    grid-area: 'main';
    grid-gap: var(--space-xl);
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    padding: 0;
    @media  screen and (min-width: 1024px) {
      grid-gap: var(--space-3xl);
    }
  }
}

@media screen and (min-width: 768px) {
  .App {
    padding: 0 80px;
  }
}

@media screen and (min-width: 1024px) {
  .App {
    padding: 0 var(--space-3xl);
  }
}